<template>
  <base-material-card
    color="success"
    inline
    class="px-5 py-3"
    filter
  >
    <template #heading>
      <div class="text-h3 font-weight-light">
        <v-icon>mdi-file-document-multiple-outline</v-icon> Отчеты
      </div>
    </template>
    <template #after-heading>
      <v-autocomplete
        v-model="DossiersOfficesFilter"
        class="pr-2"
        label="Подразделение:"
        :items="DossiersOffices"
        item-text="dossiersofficetitle"
        item-value="dossiersofficeid"
        clearable
        multiple
      />
      <v-menu
        ref="menuFrom"
        v-model="menuFrom"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            v-model="dateFromF"
            persistent-hint
            @blur="parseDate(dateFromF)"
            v-on="on"
            :disabled="tallyLoading"
          >
            <span
              slot="prepend"
              class="flex text-h4 font-weight-light"
            >с
            </span>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dateFrom"
          no-title
          locale="ru-ru"
          @input="menuFrom = false"
        />
      </v-menu>
      <v-menu
        ref="menuTo"
        v-model="menuTo"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            v-model="dateToF"
            persistent-hint
            @blur="dateTo = parseDate(dateToF)"
            @change="updateTally"
            v-on="on"
            :disabled="tallyLoading"
          >
            <span
              slot="prepend"
              class="flex text-h4 font-weight-light "
            >по
            </span>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dateTo"
          no-title
          locale="ru-ru"
          @input="menuTo = false"
        />
      </v-menu>
      <v-dialog
        ref="dialog"
        v-model="addpopup"
        :return-value.sync="date"
        persistent
        width="340px"
      >
        <template #activator="{ on }">
          <v-btn
            color="info"
            default
            rounded
            class="mt-2"
            v-on="on"
            @click="selDosOffice"
          >
            <v-icon class="mr-2">
              mdi-file-plus-outline
            </v-icon>создать отчет
          </v-btn>
        </template>
        <v-card>
          <v-card-text class="Pa-0">
            <v-row justify="center">
              <v-date-picker
                v-model="date"
                scrollable
                locale="ru-ru"
                :first-day-of-week="1"
              />
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="DossiersOfficesSelect"
                  :items="DossiersOffices"
                  item-text="dossiersofficetitle"
                  item-value="dossierofficeid"
                  solo
                />
              </v-col>
            </v-row>
            <v-row class="mt-0 pt-0">
              <v-col
                cols="12"
                class="mt-0 pt-0"
              >
                <v-checkbox
                  v-model="autofill"
                  label="Заполнить предыдущими данными"
                  class="mt-0"
                  solo
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="addpopup = false"
            >
              Отмена
            </v-btn><v-spacer />
            <v-btn
              text
              color="primary"
              :disabled="DossiersOfficesSelect === null"
              @click="addTally(date)"
            >
              Добавить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-data-table
      v-model="selectedVac"
      :headers="headersTally"
      :items="Tallys"
      class=" pa-0"
      item-key="StafferVacationID"
      :loading="tallyLoading"
      no-data-text="нет данных"
      :items-per-page="TallysItemsPerPage"
      sort-by="DateDateS"
      :footer-props="{itemsPerPageText:'Строк на странице:'}"
      @click:row="selectTally"
    >
      <template #item.statusid="{ item }">
        <v-chip
          class="my-2"
          small
          :color="statusCase(item.statusid).color"
        >
          {{ statusCase(item.statusid).text }}
        </v-chip>
      </template>
    </v-data-table>
  </base-material-card>
</template>

<script>
  export default {
    name: 'TallySheetList',
    data () {
      return {
        upadateTally: {
          dateTo: null,
          dateFrom: null,
        },
        autofill: false,
        DossiersOffices: [],
        DossiersOfficesSelect: null,
        TallysPage: 1,
        TallysItemsPerPage: 10,
        Tallys: [],
        tableLoading: false,
        addpopup: false,
        dateFrom: null,
        dateTo: null,
        dateFromF: null,
        dateToF: null,
        menuFrom: false,
        menuTo: false,
        selectedVac: [],
        date: new Date().toISOString().substr(0, 10),
        dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      }
    },
    computed: {
      DossiersOfficesFilter: {
        get () {
          return this.$store.getters.DossiersOfficesFilter
        },
        set (newVal) {
          console.log('newval', newVal)
          const payload = {
            cookieName: 'DossiersOfficesFilter',
            cookieValue: newVal,
          }
          this.$store.dispatch('setCookies', payload)
          this.$store.commit('setDossiersOfficesFilter', newVal)
        },
      },
      headersTally () {
        return [
          {
            text: ' Дата',
            align: 'center',
            value: '_datedates',
            width: '30%',
            sortable: true,
          },
          {
            text: ' Статус',
            align: 'left',
            value: 'statusid',
            sortable: true,
          },
          {
            text: ' Подразделение',
            align: 'left',
            value: 'dossiersofficetitle',
            sortable: true,
            filter: value => {
              if (this.DossiersOfficesFilter == null || (this.DossiersOfficesFilter && this.DossiersOfficesFilter.length === 0)) return true
              return this.DossiersOfficesFilter.includes(value)
            },
          },
          {
            text: ' Создал',
            align: 'left',
            value: 'creatorfio',
            sortable: true,
          },
          {
            text: ' ',
            align: 'left',
            value: 'action',
            width: '40px',
            sortable: false,
          },
        ]
      },
      tallyLoading () {
        return this.upadateTally.dateTo === this.dateTo && this.upadateTally.dateFrom === this.dateFrom
      },
    },
    watch: {
      dateTo (val) {
        this.dateToF = this.formatDate(this.dateTo)
        const payload = {
          cookieName: 'dateTo',
          cookieValue: JSON.stringify({ dateFromF: this.dateFromF, dateTo: this.dateTo }),
        }
        this.$store.dispatch('setCookies', payload)
        if (this.dateFrom <= this.dateTo && this.upadateTally.dateTo !== this.dateTo && this.upadateTally.dateFrom !== this.dateFrom) {
          console.log('run to', this.upadateTally)
          this.upadateTally.dateTo = this.dateTo
          this.upadateTally.dateFrom = this.dateFrom
          this.$store.dispatch('getTally', { dateTo: this.dateTo, dateFrom: this.dateFrom }).then((resp) => {
            this.Tallys = resp
            this.upadateTally.dateTo = null
            this.upadateTally.dateFrom = null
            // console.log(resp)
          }).catch((e) => {
            console.log(e)
          })
        }
      },
      dateFrom (val) {
        this.dateFromF = this.formatDate(this.dateFrom)
        const payload = {
          cookieName: 'dateFrom',
          cookieValue: JSON.stringify({ dateFromF: this.dateFromF, dateFrom: this.dateFrom }),
        }
        this.$store.dispatch('setCookies', payload)
        if (this.dateFrom <= this.dateTo && this.upadateTally.dateTo !== this.dateTo && this.upadateTally.dateFrom !== this.dateFrom) {
          console.log('run from', this.upadateTally)
          this.upadateTally.dateTo = this.dateTo
          this.upadateTally.dateFrom = this.dateFrom
          this.$store.dispatch('getTally', { dateTo: this.dateTo, dateFrom: this.dateFrom }).then((resp) => {
            this.Tallys = resp
            this.upadateTally.dateTo = null
            this.upadateTally.dateFrom = null
            // console.log(resp)
          }).catch((e) => {
            console.log(e)
          })
        }
      },
    },
    async mounted () {
      this.$store.dispatch('getDossiersOffices').then((x) => {
        this.DossiersOffices = x
        this.DossiersOfficesSelect = x[0].dossierofficeid
        this.$store.dispatch('getCookies', 'DossiersOfficesFilter').then((dos) => {
          if (dos) this.$store.commit('setDossiersOfficesFilter', dos.split(','))
        })
      })
      const dateTo = await this.$store.dispatch('getCookies', 'dateTo')
      if (dateTo) {
        this.dateTo = dateTo.dateTo
      }
      const dateFrom = await this.$store.dispatch('getCookies', 'dateFrom')
      if (dateFrom) {
        this.dateFrom = dateFrom.dateFrom
      }
      if (this.dateTo === null && this.dateFrom === null) {
        var d = new Date()
        const dateMinus = 31
        this.dateTo = new Date(d).toISOString().substr(0, 10)
        this.dateToF = this.formatDate(this.dateTo)
        d.setDate(d.getDate() - dateMinus)
        this.dateFrom = new Date(d).toISOString().substr(0, 10)
        this.dateFromF = this.formatDate(this.dateFrom)
      }
      this.$store.dispatch('getSPFEmlp', { dateTo: this.dateTo, dateFrom: this.dateFrom }).then((resp) => {
        if (resp.data.length === 1) {
          this.selectedEmp[0] = resp.data[0]
        }
      })
      // this.$store.dispatch('getTally', { dateTo: this.dateTo, dateFrom: this.dateFrom }).then((resp) => {
      //   this.Tallys = resp
      // }).catch((e) => {
      //   console.log(e)
      // })
    },
    methods: {
      updateTally () {
        console.log('update')
      },
      selDosOffice () {
        console.log('seldos')
        if (this.DossiersOfficesFilter && this.DossiersOfficesFilter.length === 1) {
          const ds = this.DossiersOffices.find(x => x.dossiersofficetitle === this.DossiersOfficesFilter[0])
          this.DossiersOfficesSelect = ds.dossierofficeid
        } else {
          this.DossiersOfficesSelect = null
        }
      },
      selectTally (item) {
        this.$emit('selectTally', item)
      },
      statusCase (item) {
        switch (parseInt(item, 10)) {
          case 2:
            return { text: 'утвержден', color: 'primary', colorHex: '#3260b0' }
          case 1:
            return { text: 'согласован', color: 'secondary', colorHex: '#42e950' }
          case -1:
            return { text: 'отклонен', color: 'warning', colorHex: '#e43c4d' }
          default:
            return { text: 'создан', color: '', colorHex: '#c7b5b5' }
        }
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      addTally (date) {
        this.addpopup = false
        this.$store.dispatch('addTally', { datedate: date, dossierofficeid: this.DossiersOfficesSelect, autofill: this.autofill ? '1' : '0' }).then((resp) => {
          this.Tallys.push({
            _datedates: resp.datedates,
            canaccept: resp.canaccept,
            creatorfio: resp.creatorfio,
            creatorid: resp.creatorid,
            datedate: resp.datedate,
            dossiersofficeid: resp.dossiersofficeid,
            dossiersofficetitle: resp.dossiersofficetitle,
            spftsid: resp.spftsid,
            statusid: resp.statusid,
          })
          this.$store.dispatch('setSnackbar', { state: true, title: null, text: 'Отчет добавлен', color: 'success', direction: 'top center' })
          console.log(resp)
        }).catch((e) => {
          console.log(e)
        })
      },
      openAdd () {
        this.addpopup = true
      },
    },
  }
</script>
