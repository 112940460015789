<template>
  <v-container
    id="widgets"
    fluid
    tag="section"
    class="pt-0"
  >
    <!-- Таблица Отчетов START -->
    <tally-sheet-list
      @selectTally="selectTally"
    />
    <!-- Таблица Отчетов END-->
    <!-- Таблица Отчета по сотрудникам  START-->
    <base-material-card
      v-if="selectedTally&&selectedTallyLoading&&timeSheetsStates"
      color="success"
      inline
      class="px-5 py-3"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>mdi-file-chart</v-icon>
          Отчет [{{ selectedTally.dossiersofficetitle }}] ( {{ selectedTally._datedates }} )
          <v-chip
            class="ma-2"
            small
            :color="statusCase(selectedTally.statusid).color"
          >
            {{ statusCase(selectedTally.statusid).text }}
          </v-chip>
        </div>
      </template>
      <template #after-heading>
        <v-btn
          v-if="!!Object.keys(haveChanges).length"
          color="#66b75c"
          default
          rounded
          class="mt-2"
          @click="saveChanges"
        >
          <v-icon class="mr-2">
            mdi-content-save
          </v-icon>
          сохранить
        </v-btn>

        <v-btn
          v-if="selectedTally.canaccept && selectedTally.statusid == 1"
          color="info"
          default
          rounded
          @click="unacceptTally(selectedTally)"
        >
          вернуть
        </v-btn>
        <v-btn
          v-if="selectedTally.canaccept && selectedTally.statusid == 1"
          color="primary"
          default
          rounded
          @click="acceptTally(selectedTally)"
        >
          утвердить
        </v-btn>
        <v-btn
          v-if="selectedTally.statusid == 0"
          color="info"
          default
          rounded
          class="mt-2"
          @click="AddEmp"
        >
          <v-icon class="mr-2">
            mdi-account-plus-outline
          </v-icon> Добавить
        </v-btn>
        <v-btn
          v-if="selectedTally.statusid==0"
          color="success"
          default
          rounded
          class="mt-2"
          :disabled="!!Object.keys(haveChanges).length"
          @click="SubmitTally"
        >
          <v-icon class="mr-2">
            mdi-check
          </v-icon> Отправить
        </v-btn>
        <v-dialog
          v-if="addEmpPopup.show"
          ref="dialogAddEmp"
          v-model="addEmpPopup.show"
          persistent
          min-width="340px"
          max-width="640px"
        >
          <v-card>
            <v-card-text class="flex justify-end">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <emp-select
                      class="pt-0 v-card__actions"
                      :emp-list="VacEmployees"
                      :disablebtn="addEmpPopup.type=='edit'"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.wsid"
                      :items="workshopsList"
                      item-text="title"
                      item-value="spfwsid"
                      item-disabled="dis"
                      label="Цех"
                      color="secondary"
                      item-color="secondary"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="editedItem.state"
                      :items="timeSheetsStates"
                      item-text="description"
                      item-value="tssid"
                      label="Статус"
                      color="secondary"
                      item-color="secondary"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      ref="BeginTimeS"
                      v-model="editedItem._begintimes"
                      type="time"
                      label="Время начала"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      ref="EndTimeS"
                      v-model="editedItem._endtimes"
                      type="time"
                      label="Время окончания"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.notes"
                      label="Примечание"
                      :error-messages="errorMessages"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions v-if="addEmpPopup.type === 'add'">
              <v-btn
                text
                @click="addEmpPopup.show = false"
              >
                отмена
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                text
                :disabled="cantAdd"
                @click="addEmpToList"
              >
                добавить
              </v-btn>
            </v-card-actions>
            <v-card-actions v-else>
              <v-btn
                color="blue darken-1"
                text
                @click="addEmpPopup.show = false"
              >
                Отменить
              </v-btn>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                :disabled="cantAdd"
                @click="updateEmpInfo(updateIndex)"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-data-table
        :headers="headersTallyList"
        :items="selectedTallyList"
        item-key="StafferVacationID"
        :loading="tableLoading"
        no-data-text="нет данных"
        height="82vh"
        fixed-header
        :items-per-page="-1"
        :footer-props="{itemsPerPageText:'Строки на странице:'}"
      >
        <template #item="{ item }">
          <tr :class="item.flgoficial ? '' : 'flgoficial'">
            <td class="pl-1">
              <span>
                {{ item.empfio }}
              </span>
              <span class="text-overline">
                ({{ item.empid }})
              </span>
            </td>
            <td>
              <v-icon>
                {{ shiftIcon(item.begintime) }}
              </v-icon>
            </td>
            <td
              class="clickabl"
              :class="[haveChanges[item.index] && haveChanges[item.index]['wsid'] ? 'changed-cell' : '', haveChanges[item.index] && !item.wsid && (!item.state || item.state == 0) ? 'changed-problem-cell' : '']"
              @click="editElement(item, item.index, 'wstitleedit')"
            >
              <span v-if="item.wstitleedit">
                <v-autocomplete
                  :ref="'wstitleedit' + item.index"
                  v-model="item.wsid"
                  :items="workshopsList"
                  item-text="title"
                  item-value="spfwsid"
                  item-disabled="dis"
                  color="secondary"
                  item-color="secondary"
                  clearable
                  @change="wsChange(item, item.index)"
                  @blur="editElement(item, item.index,'wstitleedit', false)"
                  @keydown.enter.native="keycheck($event, item, item.index, 'wstitleedit')"
                />
              </span>
              <span v-else>
                {{ item.wstitle }}
              </span>
            </td>
            <td
              class="clickabl"
              style="text-align: center"
              :class="haveChanges[item.index] && haveChanges[item.index]['_begintimes'] ? 'changed-cell' : ''"
              @click="editElement(item, item.index, 'begintimesedit')"
            >
              <span v-if="item.begintimesedit">
                <v-text-field
                  :ref="'begintimesedit' + item.index"
                  v-model="item._begintimes"
                  type="time"
                  label="Время начала"
                  @blur="editElement(item, item.index,'begintimesedit', false)"
                  @keydown.enter.native="keycheck($event, item, item.index, 'begintimesedit')"
                />
              </span>
              <span v-else>
                {{ item._begintimes }}
              </span>
            </td>
            <td
              style="text-align: center"
              :class="haveChanges[item.index] && haveChanges[item.index]['_endtimes'] ? 'changed-cell' : ''"
              @click="editElement(item, item.index, 'endtimesedit')"
            >
              <span v-if="item.endtimesedit">
                <v-text-field
                  :ref="'endtimesedit' + item.index"
                  v-model="item._endtimes"
                  type="time"
                  label="Время окончания"
                  @blur="editElement(item, item.index,'endtimesedit', false)"
                  @keydown.enter="keycheck($event, item, item.index, 'endtimesedit')"
                />
              </span>
              <span v-else>
                {{ item._endtimes }}
              </span>
            </td>
            <td
              style="text-align: center"
              :class="haveChanges[item.index] && haveChanges[item.index]['offsethours'] ? 'changed-cell' : ''"
              @click="editElement(item, item.index, 'offsethoursedit')"
            >
              <span v-if="item.offsethoursedit">
                <v-text-field
                  :ref="'offsethoursedit' + item.index"
                  v-model="item._offsethours"
                  type="time"
                  label="Время отработаное"
                  @blur="editElement(item, item.index,'offsethoursedit', false)"
                  @keydown.enter="keycheck($event, item, item.index, 'offsethoursedit')"
                />
              </span>
              <span v-else>
                {{ item._offsethours }}
              </span>
            </td>
            <td
              style="text-align: center"
              :class="haveChanges[item.index] && haveChanges[item.index]['productivity'] ? 'changed-cell' : ''"
              @click="editElement(item, item.index, 'productivityedit')"
            >
              <span v-if="item.productivityedit">
                <v-text-field
                  :ref="'productivityedit' + item.index"
                  v-model="item.productivity"
                  type="number"
                  label="Произведено"
                  @blur="editElement(item, item.index,'productivityedit', false)"
                  @keydown.enter="keycheck($event, item, item.index, 'productivityedit')"
                />
              </span>
              <span v-else>
                {{ item.productivity }}
              </span>
            </td>
            <td
              style="text-align: center"
              :class="haveChanges[item.index] && haveChanges[item.index]['fine'] ? 'changed-cell' : ''"
              @click="editElement(item, item.index, 'fineedit')"
            >
              <span v-if="item.fineedit">
                <v-text-field
                  :ref="'fineedit' + item.index"
                  v-model="item.fine"
                  type="number"
                  label="Вычет"
                  @blur="editElement(item, item.index,'fineedit', false)"
                  @keydown.enter="keycheck($event, item, item.index, 'fineedit')"
                />
              </span>
              <span v-else>
                {{ item.fine }}
              </span>
            </td>
            <td
              class="clickabl"
              :class="[haveChanges[item.index] && haveChanges[item.index]['state'] ? 'changed-cell' : '', haveChanges[item.index] && !(item.state || item.state === 0) ? 'changed-problem-cell' : '']"
              @click="editElement(item, item.index, 'tssidedit')"
            >
              <span v-if="item.tssidedit">
                <v-autocomplete
                  :ref="'tssidedit' + item.index"
                  v-model="item.state"
                  :items="timeSheetsStates"
                  item-text="description"
                  item-value="tssid"
                  label="Статус"
                  color="secondary"
                  item-color="secondary"
                  :disabled="item.flgfromtimesheet"
                  @blur="editElement(item, item.index,'tssidedit', false)"
                  @keydown.enter.native="keycheck($event, item, item.index, 'tssidedit')"
                />
              </span>
              <span v-else-if="item.state !== null">
                {{ stateName[item.state] }}
              </span>
              <span v-else>
                -
              </span>
            </td>
            <td
              class="clickabl"
              :class="haveChanges[item.index] && haveChanges[item.index]['notes'] ? haveChanges[item.index]['notes'] : ''"
              @click="editElement(item, item.index, 'notesedit')"
            >
              <span v-if="item.notesedit">
                <v-text-field
                  :ref="'notesedit' + item.index"
                  v-model="item.notes"
                  @blur="editElement(item, item.index,'notesedit', false)"
                  @keydown.enter.native="keycheck($event, item, item.index, 'notesedit')"
                />
              </span>
              <span
                v-else-if="haveChanges[item.index] && (haveChanges[item.index]['notes'] === 'changed-problem-cell')"
                style="color: indianred"
              >
                Некорректное время, необходимо внести примечание
              </span>
              <span v-else>
                {{ item.notes }}
              </span>
            </td>
            <td>
              <span class="d-flex pt-1">
                <v-btn
                  v-if="selectedTally.statusid===0"
                  class="px-2 ml-1"
                  color="info"
                  min-width="0"
                  small
                  @click="editEmpFromTally(item)"
                >
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <!--    todo split to 2 workshop-->
                <!--               <v-btn-->
                <!--                  v-if="selectedTally.statusid===0 && item.begintime && item.endtime"-->
                <!--                  class="px-2 ml-1"-->
                <!--                  color="success"-->
                <!--                  min-width="0"-->
                <!--                  small-->
                <!--                  @click="splitEmpFromTally(item)"-->
                <!--                >-->
                <!--                  <v-icon small>-->
                <!--                    mdi-swap-vertical-bold-->
                <!--                  </v-icon>-->
                <!--                </v-btn>-->
                <v-btn
                  v-if="item.candelete>0 && selectedTally.statusid===0"
                  class="px-2 ml-1"
                  color="error"
                  min-width="0"
                  small
                  @click="QdelEmpFromTally(item)"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </base-material-card>
    <!-- Таблица Отчета по сотрудникам  START-->

    <v-progress-linear
      v-if="!selectedTallyLoading"
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="6"
    />

    <v-dialog
      ref="dialog"
      v-model="delpopup"
      persistent
      width="290px"
    >
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-icon
            aria-label="Close"
            @click="delpopup = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="text-body-1 text-center">
          Удалить запись
        </v-card-text>
        <v-card-actions class="mb-2">
          <v-spacer />
          <v-btn
            color="error"
            depressed
            default
            text
            @click="delpopup = false"
          >
            нет
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            depressed
            default
            text
            @click="deleteEmpFromTally({value: editedItem, index: delIndex})"
          >
            да
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import EmpSelect from '../components/empSelect'
  import tallySheetList from './components/tallySheetList'

  export default {
    name: 'Simplefoods',
    components: {
      tallySheetList,
      EmpSelect,
    },
    data () {
      return {
        confirmAction: {
          show: false,
          actionName: null,
        },
        timeSheetsStatesSel: {},
        updateIndex: null,
        delIndex: null,
        delpopup: false,
        formHasErrors: false,
        normHours: 12,
        editedItem: {},
        dialogEditEmp: false,
        empSelectDialog: false,
        workshopsList: [],
        selectedTallyLoading: true,
        addEmpPopup: {
          show: false,
          type: '',
        },
        selectedTallyList: [],
        selectedTallyListSnapShot: [],
        selectedTallyLink: null,
        selectedTally: null,
        tableLoading: false,
        showFilter: false,
        headersTallyList: [
          {
            text: 'ФИО',
            align: 'left',
            value: 'empfio',
            width: '16%',
            sortable: true,
          },
          {
            text: 'Смена',
            align: 'left',
            value: 'wsshift',
            width: '4%',
            sortable: true,
          },
          {
            text: 'Цех',
            align: 'left',
            value: 'wstitle',
            width: '10%',
            sortable: true,
          },
          {
            text: 'Время начала',
            align: 'center',
            width: '10%',
            value: '_begintimes',
            sortable: true,
          },
          {
            text: 'Время окончания',
            align: 'center',
            width: '10%',
            value: '_endtimes',
            sortable: true,
          },
          {
            text: 'Отработаные часы',
            align: 'center',
            width: '5%',
            value: 'offsethours',
            sortable: true,
          },
          {
            text: 'Произведено',
            align: 'center',
            width: '4%',
            value: 'productivity',
            sortable: true,
          },
          {
            text: 'Вычет',
            align: 'center',
            width: '4%',
            value: 'fine',
            sortable: true,
          },
          {
            text: 'Статус',
            align: 'center',
            width: '12%',
            value: 'state',
            sortable: true,
          },
          {
            text: 'Примечание',
            align: 'left',
            width: '13%',
            value: 'notes',
            sortable: false,
          },
          {
            text: ' ',
            align: 'left',
            width: '5%',
            value: 'action',
            sortable: false,
          },
        ],
        editsArray: [
          'wstitleedit',
          'begintimesedit',
          'endtimesedit',
          'offsethoursedit',
          'tssidedit',
        ],
      }
    },
    computed: {
      ...mapGetters({
        VacEmployees: 'VacEmployees',
        timeSheetsStates: 'timeSheetsStates',
      }),
      selectedEmp: {
        get: function () {
          return this.$store.getters.selectedEmp
        },
        set: function (newValue) {
          this.$store.dispatch('setSelectedEmp', newValue)
        },
      },
      cantAdd () {
        return !(this.selectedEmp[0] && this.editedItem.wsid && this.checkNormTime)
      },
      errorMessages () {
        return this.checkNormTime ? '' : 'Некорректное время, необходимо внести примечание'
      },
      checkNormTime () {
        if (this.editedItem.state !== 0) return true
        return !((this.editedItem._begintimes === '00:00' && this.editedItem._endtimes === '00:00') ||
          !this.editedItem._endtimes || !this.editedItem._begintimes)
      },
      haveChanges () {
        const havechanges = {}
        this.selectedTallyList.forEach((item, index) => {
          if (JSON.stringify(this.selectedTallyListSnapShot[index]) !== JSON.stringify(this.selectedTallyList[index])) {
            havechanges[index] = {}
            for (const keyValue in this.selectedTallyListSnapShot[index]) {
              if (this.selectedTallyListSnapShot[index][keyValue] !== this.selectedTallyList[index][keyValue]) {
                havechanges[index][keyValue] = 'changed-cell'
              }
            }
            if (!havechanges[index].notes) {
              havechanges[index].notes = this.checkRowNormTime(index) ? '' : 'changed-problem-cell'
            }
          }
        })
        return havechanges
      },
      stateName () {
        const statesNames = {}
        this.timeSheetsStates.forEach((s) => {
          statesNames[s.tssid] = s.description
        })
        return statesNames
      },
    },
    mounted () {
      this.$store.dispatch('GetWorkshopsList').then((resp) => {
        this.workshopsList = resp
        console.log(resp)
      }).catch((e) => {
        console.log(e)
      })
      this.$store.dispatch('getSPFTimeSheetStates').then((x) => {
        this.timeSheetsStatesSel = x[0].id
      })
    },
    methods: {
      checkRowNormTime (indx) {
        if (this.selectedTallyList[indx].state !== 0) return true
        return !((this.selectedTallyList[indx]._begintimes === '00:00' && this.selectedTallyList[indx]._endtimes === '00:00') ||
          !this.selectedTallyList[indx]._endtimes || !this.selectedTallyList[indx]._begintimes)
      },
      saveChanges () {
        // todo save changes method
        console.log('sc')
        let haveProblems = false
        for (const row in this.haveChanges) {
          console.log('haveProblems1', row)
          for (const keys in this.haveChanges[row]) {
            console.log('haveProblems2', keys)
            if (this.haveChanges[row][keys] === 'changed-problem-cell' || (this.haveChanges[row] && ((!this.selectedTallyList[row].wsid && this.selectedTallyList[row].state == 0) || this.selectedTallyList[row].state == null))) {
              haveProblems = true
            }
          }
        }
        setTimeout(() => {
          console.log('haveProblems', haveProblems)
          if (!haveProblems) {
            for (const row in this.haveChanges) {
              console.log(` ${row} are change`)
              this.updateEmpInfoByIndex(row)
            }
          } else {
            this.$store.dispatch('setSnackbar', { state: true, text: 'Есть некорректно заполненые данные', color: 'warning', direction: 'top center' })
          }
        })
      },
      updateEmpInfoByIndex (index) {
        console.log('update', index)
        console.log('update', this.selectedTallyList[index])
        const send = {
          index: index,
          SpfTsEmpID: this.selectedTallyList[index]._spftsempid,
          SpfTsID: this.selectedTally.spftsid,
          SpfWsID: this.selectedTallyList[index].wsid,
          EmpID: this.selectedTallyList[index].empid,
          BeginTime: this.selectedTallyList[index]._begintimes,
          EndTime: this.selectedTallyList[index]._endtimes,
          offsethours: this.selectedTallyList[index]._offsethours,
          Notes: this.selectedTallyList[index].notes,
          State: this.selectedTallyList[index].state,
          productivity: this.selectedTallyList[index].productivity,
          fine: this.selectedTallyList[index].fine,
        }
        console.log('s1', send)
        this.$store.dispatch('setTallysheetEmployee', send).then((resp) => {
          if (resp && resp.Code == -1) {
            this.$store.dispatch('setSnackbar', { state: true, text: resp, color: 'warning', direction: 'top center' })
          } else {
            console.log('resp', resp)
            resp._offsethours = resp.offsethours_out
            resp.productivity = resp.productivity_out
            resp.fine = resp.fine_out
            resp.flgoficial = this.selectedTallyList[index].flgoficial
            this.selectedTallyList.splice(index, 1, resp)
            this.selectedTallyListSnapShot.splice(index, 1, JSON.parse(JSON.stringify(resp)))
            this.addEmpPopup = { show: false, type: '' }
            this.$store.dispatch('setSnackbar', { state: true, text: 'Запись обновлена', color: 'success', direction: 'top center' })
          }
        }).catch((e) => {
          console.log(e)
        })
      },
      wsChange (item) {
        console.log(item.wsid)
        item.wstitle = this.workshopsList.find(x => x.spfwsid === item.wsid)?.title
      },
      keycheck (e, item, index, editname) {
        const colId = this.editsArray.findIndex(x => x === editname)
        this.$set(item, editname, false)
        if (this.editsArray.length !== (colId + 1)) {
          editname = this.editsArray[colId + 1]
        } else {
          editname = this.editsArray[0]
          index = index + 1
          item = this.selectedTallyList[index]
        }
        this.editElement(item, index, editname)
      },
      editElement (item, index, editname, state = true) {
        this.$set(item, editname, state)
        this.$set(this.selectedTallyListSnapShot[index], editname, state)
        if (state) {
          const refname = editname + index
          setTimeout(() => {
            this.$refs[refname].focus()
            if (editname === 'wstitleedit' || editname === 'tssidedit') {
              setTimeout(() => {
                this.$refs[refname].isMenuActive = true
              })
            }
          })
        }
      },
      splitEmpFromTally (item) {
        item.NewBeginTime = new Date()
        alert('item' + JSON.stringify(item))
      },
      getTimeDiff (timeBegin, timeEnd) {
        timeBegin = timeBegin.split(':')
        timeEnd = timeEnd.split(':')
        return timeEnd - timeBegin
      },
      status (item) {
        const stateName = ['создан', 'на утверждении', 'утвержден']
        return stateName[parseInt(item)]
      },
      unacceptTally (item) {
        if (item.statusid > 0) {
          this.$confirm({
            title: 'Вернуть список на доработку?',
            text: null,
            acceptText: 'Вернуть',
            cancelText: 'отмена',
          }).then(() => {
            const newstate = 0
            this.$store.dispatch('setTallyNewStatus', { spftsid: item.spftsid, newstatus: newstate }).then((resp) => {
              this.$store.dispatch('setSnackbar', { state: true, text: 'Отчет отправлен на дорабртку.', color: 'success', direction: 'top center' })
              this.selectedTally.statusid = newstate
              this.selectedTallyLink.statusid = newstate
            }).catch((e) => {
              console.log(e)
            })
          }).catch(() => {
          })
        } else {
          console.log('go away')
        }
      },
      acceptTally (item) {
        if (item.statusid > 0) {
          this.$confirm({
            title: 'Утвердить список?',
            text: null,
            acceptText: 'Утвердить',
            cancelText: 'отмена',
          }).then(() => {
            const newstate = parseInt(item.statusid) + 1
            this.$store.dispatch('setTallyNewStatus', { spftsid: item.spftsid, newstatus: newstate }).then((resp) => {
              this.$store.dispatch('setSnackbar', { state: true, text: 'Отчет согласован.', color: 'success', direction: 'top center' })
              this.selectedTally.statusid = newstate
              this.selectedTallyLink.statusid = newstate
            }).catch((e) => {
              console.log(e)
            })
          }).catch(() => {
          })
        } else {
          console.log('go away')
        }
      },
      SubmitTally () {
        let checkFill = true
        this.selectedTallyList.forEach((x) => {
          if (x.state == null) {
            console.log('check it:', x)
            x.check = true
            checkFill = false
          }
        })
        if (checkFill) {
          this.$store.dispatch('TallyStatusChange', { spftsid: this.selectedTally.spftsid, newstatus: this.selectedTally.statusid + 1 }).then((resp) => {
            this.$store.dispatch('setSnackbar', { state: true, text: 'Отчет отправлен на согласование.', color: 'success', direction: 'top center' })
            this.selectedTally.statusid = 1
            this.selectedTallyLink.statusid = 1
            // this.timeSheetsStates.find(x => x.spftsid === resp).Desc
          }).catch((e) => {
            console.log(e)
          })
        } else {
          this.$store.dispatch('setSnackbar', { state: true, text: 'Не указано время работы или статус всех сотрудников', color: 'warning', direction: 'top center' })
        }
        console.log('check and send')
      },
      shiftIcon (item) {
        const from = 4 * (1 / 24)
        const till = 16 * (1 / 24)
        if (item || item === 0) {
          if (from < item && item < till) return 'mdi-white-balance-sunny'
          return 'mdi-weather-night'
        } else {
          return ''
        }
      },
      AddEmp () {
        this.selectedEmp = []
        this.editedItem = {}
        this.addEmpPopup = { show: true, type: 'add' }
      },
      updateEmpInfo (index) {
        // console.log('update', index)
        const send = {
          index: index,
          SpfTsEmpID: this.editedItem._spftsempid,
          SpfTsID: this.selectedTally.spftsid,
          SpfWsID: this.editedItem.wsid,
          EmpID: this.selectedEmp[0].empid,
          BeginTime: this.editedItem._begintimes,
          EndTime: this.editedItem._endtimes,
          Notes: this.editedItem.notes,
          State: this.editedItem.state,
        }
        // console.log('s1', send)
        this.$store.dispatch('setTallysheetEmployee', send).then((resp) => {
          if (resp && resp.Code == -1) {
            this.$store.dispatch('setSnackbar', { state: true, text: resp, color: 'warning', direction: 'top center' })
          } else {
            this.selectedTallyList.splice(index, 1, resp)
            this.selectedTallyListSnapShot.splice(index, 1, JSON.parse(JSON.stringify(resp)))
            this.addEmpPopup = { show: false, type: '' }
            this.$store.dispatch('setSnackbar', { state: true, text: 'Запись обновлена', color: 'success', direction: 'top center' })
          }
        }).catch((e) => {
          console.log(e)
        })
      },
      editEmpFromTally (item) {
        this.updateIndex = this.selectedTallyList.findIndex(x => x._spftsempid == item._spftsempid)
        this.selectedEmp[0] = { empid: item.empid, empfio: item.empfio }
        this.editedItem = Object.assign({}, item)
        this.addEmpPopup = { show: true, type: 'edit' }
      },
      QdelEmpFromTally (item) {
        this.delpopup = true
        this.delIndex = this.selectedTallyList.findIndex(x => x._spftsempid == item._spftsempid)
        this.editedItem = Object.assign({}, item)
      },
      deleteEmpFromTally (item) {
        this.$store.dispatch('delTallysheetEmployee', item.value).then((resp) => {
          this.selectedTallyList.splice(item.index, 1)
          this.$store.dispatch('setSnackbar', { state: true, text: 'Запись удалена', color: 'success', direction: 'top center', time: 3000 })
          this.delpopup = false
        }).catch((e) => {
          console.log(e)
          this.delpopup = false
        })
      },
      addEmpToList () {
        if (this.editedItem.wsid) {
          const send = {
            SpfTsID: this.selectedTally.spftsid,
            SpfWsID: this.editedItem.wsid,
            EmpID: this.selectedEmp[0].empid,
            BeginTime: this.editedItem._begintimes,
            EndTime: this.editedItem._endtimes,
            Notes: this.editedItem.notes,
            State: this.editedItem.state,
          }
          this.$store.dispatch('setTallysheetEmployee', send).then((resp) => {
            if (resp && resp.result >= 0) {
              resp.candelete = 1
              const index = this.selectedTallyList.findIndex(item => item._spfwsid == resp._spfwsid && item.empid == resp.empid)
              console.log(index)
              if (index >= 0) {
                this.selectedTallyList.splice(index, 1, resp)
              } else {
                this.selectedTallyList.push(resp)
              }
              this.addEmpPopup = { show: false, type: '' }
              console.log(resp)
            } else {
              this.$store.dispatch('setSnackbar', { state: true, text: resp, color: 'warning', direction: 'top center' })
            }
          }).catch((e) => {
            console.log(e)
          })
        }
      },
      addEmpToListbyItem (item) {
        if (this.editedItem.wsid) {
          const send = {
            SpfTsEmpID: item.tsempid,
            SpfTsID: item.tsid,
            SpfWsID: item.wsid,
            EmpID: item.empid,
            NewBeginTime: item.NewTime,
          }
          //   SPFTSEMPID
          //   SPFTSID
          //   SPFWSID
          //   EMPID
          //   NEWBEGINTIME
          this.$store.dispatch('splitTallysheetEmployee', send).then((resp) => {
            if (resp && resp.result >= 0) {
              resp.candelete = 1
              const index = this.selectedTallyList.findIndex(item => item._spfwsid == resp._spfwsid && item.empid == resp.empid)
              console.log(index)
              if (index >= 0) {
                this.selectedTallyList.splice(index, 1, resp)
              } else {
                this.selectedTallyList.push(resp)
              }
              this.addEmpPopup = { show: false, type: '' }
              console.log(resp)
            } else {
              this.$store.dispatch('setSnackbar', { state: true, text: resp, color: 'warning', direction: 'top center' })
            }
          }).catch((e) => {
            console.log(e)
          })
        }
      },
      selectTally (item) {
        // console.log(this.selectedTally)
        if (!this.selectedTally) {
          this.selectedTally = { spftsid: 0 }
        }
        if (this.selectedTally.spftsid !== item.spftsid) {
          this.selectedTallyLoading = false
          this.$store.dispatch('getTallysheetEmpsList', { SpfTsID: item.spftsid, DossiersOfficeID: item.dossiersofficeid }).then((resp) => {
            this.selectedTally = Object.assign({}, item)
            this.selectedTallyLink = item
            this.selectedTallyList = resp.map((items, index) => ({ ...items, index: index }))
            this.selectedTallyListSnapShot = JSON.parse(JSON.stringify(this.selectedTallyList))
            this.selectedTallyLoading = true
            // console.log(resp)
          }).catch((e) => {
            this.selectedTallyLoading = true
            console.log(e)
          })
        }
      },
      statusCase (item) {
        switch (parseInt(item, 10)) {
          case 2:
            return { text: 'утвержден', color: 'primary', colorHex: '#3260b0' }
          case 1:
            return { text: 'согласован', color: 'secondary', colorHex: '#42e950' }
          case -1:
            return { text: 'отклонен', color: 'warning', colorHex: '#e43c4d' }
          default:
            return { text: 'создан', color: '', colorHex: '#c7b5b5' }
        }
      },
    },
  }
</script>

<style>
  .flgoficial {
    background-color: rgba(73, 210, 255, 0.4);
  }
  .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: #2d2828!important;
  }
  .clickabl {
    padding-left: 6px!important;
    padding-right: 6px!important;
    cursor: pointer;
  }
  .checkit{
    background-color: salmon;
  }
  .changed-problem-cell {
    background-color: #f8dfd5;
  }
  .changed-cell {
    background-color: #e0fcde;
  }
</style>
